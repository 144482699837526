export const awsConfiguration = {
  host: 'a3rt49fmi74by0-ats.iot.eu-west-3.amazonaws.com', // 'YourAwsIoTEndpoint', e.g. 'prefix.iot.us-east-1.amazonaws.com'
  apiVersion: "Beta",
  apiKey: 'AIzaSyDa6ZFf53TRQWAUad0vTl-2HZ5Hn82pf2c'
};

export const AppConfig = {
  Beta: 'https://y7szq49w1m.execute-api.eu-west-3.amazonaws.com/Beta',
  Prod: 'https://y7szq49w1m.execute-api.eu-west-3.amazonaws.com/Prod',
  RC: 'https://y7szq49w1m.execute-api.eu-west-3.amazonaws.com/RC'
};
